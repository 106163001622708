import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom";

import PresentItem from "../items/PresentItem";
import OwnItem from "../items/OwnItem";
import { Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";

class UserList extends Component {
  state = {
    currentUser: null,
    loading: true,
    isEditingNotes: false,
    notes: null,
    dropdownOpen: false,
    dropdownYearValue: 2023
  };

  componentDidMount() {
    this.setCurrentUser();
  }

  handleChange = event => {
    // console.log("change", event.target.name)
    this.setState({ [event.target.name]: event.target.value });
  };

  setCurrentUser() {
    if (!isEmpty(localStorage.getItem("jwtToken"))) {
      const user = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(user);

      this.setState({
        currentUser: decoded
      });

      this.getPageUser();
    }
  }

  getPageUser() {
    axios.get(`/api/users/user/${this.props.match.params.id}`).then(user => {
      // console.log("user", user.data)
      const id = user.data._id;
      this.getUserPresents(id, 2023);
      // console.log("ty", user)
      this.setState({
        pageUser: user.data,
        notes: user.data.listNotes
      });
    });
  }

  getUserPresents(pageUser, year) {
    let { dropdownYearValue } = this.state
    // console.log("pageUser", pageUser);
    axios.get(`/api/presents/user/${pageUser}/${year}`).then(res => {
      const presents = res.data;

      // console.log("top", pageUser, res.data);
      this.setState({
        presents,
        loading: false
      });
    });
  }

  toggleEditListNotes() {
    // console.log("edit list yall")
    let { isEditingNotes } = this.state
    this.setState({ isEditingNotes: !isEditingNotes })
  }

  toggleDropdown() {
    let { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  setYear(year) {
    this.setState({ dropdownYearValue: year })
    this.getUserPresents(this.props.match.params.id, year);
  }

  updateListNotes(wasSaved) {
    if (wasSaved) {
      let { notes, pageUser } = this.state

      axios.put(`/api/users/current/list-notes`, { listNotes: notes }).then(user => {
        var updatedUser = pageUser
        updatedUser.listNotes = notes
  
        this.setState({
          notes: user.data.listNotes,
          pageUser: updatedUser
        });
        this.toggleEditListNotes()
      });

    } else {
      let { pageUser } = this.state
      this.setState({ notes: pageUser.listNotes })
      this.toggleEditListNotes()
    }
  }

  listNotes() {
    let { currentUser, pageUser, isEditingNotes, notes } = this.state
    // console.log(currentUser.id, pageUser._id)
    let isCurrentUser = currentUser.id === pageUser._id

    if (isEditingNotes) {
      return (
        <div>
          <Row>
            <Col className="col-10">
              <textarea name="notes" 
                        style={{ width: "100%" }}
                        onChange={this.handleChange}
                        >{notes}</textarea>
            </Col>
            <Col className="col-1" >
              <Row>
              <button style={{ background: "none", border: "none", cursor: "pointer", outline: "none" }} 
                  onClick={ this.updateListNotes.bind(this, true) }>              
                <span
                  aria-label="search"
                  role="img"
                  text-align="right"
                  style={{ fontSize: "16px", padding: "3px" }}
                  className="ml-auto"
                >
                  ✅
                </span>
              </button>
              </Row>
              <Row>
              <button style={{ background: "none", border: "none", cursor: "pointer", outline: "none" }} 
                  onClick={ this.updateListNotes.bind(this, false) }>              
                <span
                  aria-label="search"
                  role="img"
                  text-align="right"
                  style={{ fontSize: "16px", padding: "3px" }}
                  className="ml-auto"
                >
                  ❌
                </span>
              </button>
              </Row>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div>
        { isCurrentUser ? 
        <div>
            <button style={{ background: "none", border: "none", cursor: "pointer", outline: "none" }} 
            onClick={ this.toggleEditListNotes.bind(this) }>              
            <span
              aria-label="search"
              role="img"
              text-align="right"
              style={{ fontSize: "16px", padding: "3px" }}
              className="ml-auto"
            >
              ✏️
            </span>
            <span>{notes || !isCurrentUser ? notes : " Add general notes for your wish list"}</span>
          </button>
          </div>
          : null  
        }
      </div>
      )
    }
  }

  render() {
    let { loading, currentUser, pageUser, presents, dropdownOpen, dropdownYearValue } = this.state 

    if (isEmpty(localStorage.getItem("jwtToken"))) {
      return (
        <div>
          <Redirect to="/login" />
        </div>
      );
    } else {
      if (!loading) {
        // const user = currentUser.id;
        // const pageUser = pageUser._id;

        const presentItems =
          currentUser.id !== pageUser._id
            ? presents.map(present => (
                <PresentItem
                  key={present._id}
                  id={present._id}
                  item={present.item}
                  link={present.link}
                  notes={present.notes}
                  userWished={present.userWished}
                  userGifted={present.userGifted}
                  user={currentUser}
                  year={present.year}
                />
              ))
            : presents.map(present => (
                <OwnItem
                  key={present._id}
                  id={present._id}
                  item={present.item}
                  link={present.link}
                  notes={present.notes}
                  userWished={present.userWished}
                  year={present.year}
                />
              ));

              // const toggle = () => setDropdownOpen((prevState) => !prevState);

        return (
          <div>
            <Row>
              <Col >
                <h3 className="header">{`${pageUser.name}'s List`}</h3>
              </Col>
              <Col>
              <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown.bind(this)}>
                <DropdownToggle caret className="dropdown-button button">{dropdownYearValue}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.setYear.bind(this, 2023)}>2023</DropdownItem>
                  <DropdownItem onClick={this.setYear.bind(this, 2022)}>2022</DropdownItem>
                  <DropdownItem onClick={this.setYear.bind(this, 2021)}>2021</DropdownItem>
                  <DropdownItem onClick={this.setYear.bind(this, 2020)}>2020</DropdownItem>
                  <DropdownItem onClick={this.setYear.bind(this, 2019)}>2019</DropdownItem>
                  <DropdownItem onClick={this.setYear.bind(this, 2018)}>2018</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </Col>
            </Row>
            {/* <Row> */}
              {this.listNotes()}
            {/* </Row> */}
            <hr />
            {presentItems}
          </div>
        );
      } else {
        return <p>Loading...</p>;
      }
    }
  }
}
export default UserList;
