import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "./App.css";

import setAuthToken from "./utils/setAuthToken";
import AppNavbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
// import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Home from "./components/pages/Home";
import UserList from "./components/pages/UserList";
import Add from "./components/pages/Add";

var decoded = "";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  decoded = jwt_decode(localStorage.jwtToken);

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.clear();
    // clearCurrentProfile();
    window.location.href = "/login";
  }
}

class App extends Component {
  state = {
    user: decoded
  };

  componentDidMount() {
    this.setState({ user: decoded });
  }

  render() {
    const user = this.state.user.id;

    return (
      <Router>
        <div>
          <AppNavbar />
          <div className="container">
            {/* <Route exact path="/register" component={Register} /> */}
            <Route exact path="/" component={Home} user={user} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/list/:id" component={UserList} />
            <Route exact path="/add" component={Add} />
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
