import React, { Component } from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import isEmpty from "../../validation/is-empty";

import EditModal from "./EditModal";
import RepeatModal from "./RepeatModal";

export default class OwnItem extends Component {
  render() {
    const { item, link, notes, id, userWished, year } = this.props;

    const isCurrentYear = year === 2023
    const linkIcon = !isEmpty(link) ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px" }}
          className="ml-auto"
        >
          🔗
        </span>
      </a>
    ) : (
      <span
        aria-label="search"
        role="img"
        text-align="right"
        style={{ fontSize: "30px" }}
        className="ml-auto"
      >
        ▪️
      </span>
    );

    return (
      <Card body outline color="warning" className="mb-2 p-2">
        <CardBody className="p-2">
          <Row>
          <Col className={`col-${isCurrentYear ? "8" : "6"}`}>
              <CardTitle className="mb-0">{item}</CardTitle>
              <CardText>
                <small>{notes}</small>
              </CardText>
            </Col>
            {isCurrentYear ? (
              null
            ) : (
              <Col className="col-2">
              <RepeatModal
                id={id}
                item={item}
                notes={notes}
                year={year}
                link={link}
               />
            </Col>
            )}
            <Col className="col-2">{linkIcon}</Col>
            <Col className="col-2">
              <EditModal
                id={id}
                item={item}
                link={link}
                notes={notes}
                userWished={userWished}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
