import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "reactstrap";

class AppNavbar extends Component {
  render() {
    return (
      <div>
        <Navbar className="mb-4" color="danger">
          <Link className="navbar-brand " to="/" style={{ color: "white" }}>
            <h5 className="my-2">
              <span
                aria-label="search"
                role="img"
                text-align="right"
                style={{ fontSize: "20px", paddingRight: "8px" }}
                className="ml-auto"
              >
                🎄   
              </span>
              XMAS
              </h5>
          </Link>
          <Nav className="ml-auto" navbar>
            <Link to="/add" style={{ color: "white" }}>
              <NavItem className={"button"}>Add Item</NavItem>
            </Link>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default AppNavbar;
