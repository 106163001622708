import React, { Component } from "react";
import { Card, CardTitle, CardText, Row, Col } from "reactstrap";
import isEmpty from "../../validation/is-empty";

import GiftModal from "./GiftModal";

export default class PresentItem extends Component {
  render() {
    const { item, link, notes, id, user, userGifted, year } = this.props;

    const outlineColor =
      userGifted && userGifted === user.id
        ? "warning"
        : userGifted
        ? "danger"
        : year == 2023 
        ? "success"
        : "secondary" ;

    const linkIcon = !isEmpty(link) ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px" }}
          className="ml-auto"
        >
          🔗
        </span>
      </a>
    ) : (
      <span
        aria-label="search"
        role="img"
        text-align="right"
        style={{ fontSize: "30px" }}
        className="ml-auto"
      >
        ▪️
      </span>
    );

    return (
      <Card body outline color={outlineColor} className="mb-2 p-2">
        <Row>
          <Col className="col-8">
            <CardTitle className="mb-1">{item}</CardTitle>
            <CardText style={{ lineHeight: "92%" }}>
              <small>{notes}</small>
            </CardText>
          </Col>
          <Col className="col-2">{linkIcon}</Col>
          <Col className="col-2">
            <GiftModal
              id={id}
              item={item}
              link={link}
              notes={notes}
              user={user}
              userGifted={userGifted}
              year={year}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
