import React, { Component } from "react";
import {
  Button,
  // Container,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import axios from "axios";
// import { Link } from "react-router-dom";

class GiftModal extends Component {
  state = {
    modal: false,
    id: this.props.id,
    item: this.props.item,
    notes: this.props.notes,
    user: this.props.user,
    userGifted: this.props.userGifted,
    submitted: false,
    submitMessage: "",
    color: "",
    year: this.props.year
  };

  // addStory = story => {
  //   Axios.post("/api/stories", story).then(story => console.log(story));
  // };

  toggle = () => {
    const { userGifted, user, year } = this.state;

    // console.log("toggle", userGifted, user, year)

    if ((userGifted && user.id !== userGifted) || year != 2023) {
      return;
    }

    this.setState({
      modal: !this.state.modal
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  giftItem = () => {
    const gifter = {
      userGifted: this.state.user.id
    };

    axios.put(`/api/presents/gift/${this.state.id}`, gifter).then(res => {
      this.setState({
        userGifted: res.data.userGifted,
        submitted: true,
        submitMessage: `${res.data.item} has been gifted`
      });
    });

    // this.setState({ userGifted: "tom" });
  };

  ungiftItem = () => {
    axios.put(`/api/presents/gift/${this.state.id}`).then(res =>
      this.setState({
        userGifted: null,
        submitted: true,
        submitMessage: `${res.data.item} has been ungifted`
      })
    );

    // this.setState({ userGifted: "" });
  };

  render() {
    const {
      item,
      notes,
      userGifted,
      user,
      submitMessage,
      submitted,
      year
    } = this.state;

    const giftIcon =
      userGifted && userGifted === user.id ? (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          onClick={this.toggle}
        >
          ⭐️
        </span>
      ) : userGifted ? (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px" }}
          className="ml-auto"
          onClick={this.toggle}
        >
          🔒
        </span>
      ) : year != 2023 ? (
        <span
        aria-label="search"
        role="img"
        text-align="right"
        style={{ fontSize: "30px", cursor: "pointer" }}
        className="ml-auto"
        onClick={this.toggle}
      >
        ⌛️
      </span>
      ) : (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          onClick={this.toggle}
        >
          🎁
        </span>
      )

    const outlineColor = userGifted ? "danger" : "success";

    const submitButton = !userGifted ? (
      <Button color="success" onClick={this.giftItem} block>
        Gift Item
      </Button>
    ) : (
      <Button color="danger" onClick={this.ungiftItem} block>
        Ungift Item
      </Button>
    );

    const submitFeedback = submitted ? (
      <p className="lead text-center">{submitMessage}</p>
    ) : (
      <div />
    );

    return (
      <div>
        {giftIcon}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Gift Item</ModalHeader>
          <ModalBody>
            <Card body outline color={outlineColor} className="mb-2 p-2">
              <CardTitle>{item}</CardTitle>
              <CardText>{notes}</CardText>
            </Card>
            <hr />
            {submitFeedback}

            <Row>
              <Col>
                <Button color="dark" onClick={this.toggle} block>
                  Close
                </Button>
              </Col>
              <Col>{submitButton}</Col>
            </Row>

            {/* <FormGroup>
                <Label for="ther">Someone else is gifting this item</Label>
                <Input
                  type="radio"
                  name="gift"
                  id="gift"
                  onChange={this.handleChange}
                />
              </FormGroup> */}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default GiftModal;
