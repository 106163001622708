import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";
import { Row, Col, Jumbotron } from "reactstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";

import ListItem from "../items/ListItem";

class Lists extends Component {
  state = {
    user: "",
    id: "",
    loading: true,
    showEgg: false
  };

  componentDidMount() {
    this.setCurrentUser();
    this.getTimeTillXmas();
  }

  setCurrentUser = () => {
    if (!isEmpty(localStorage.getItem("jwtToken"))) {
      const user = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(user);
      this.setState({ user: decoded });

      this.setOtherUsers(decoded);
    }
  };

  setOtherUsers(decoded) {
    axios
      .get("api/users/all")
      .then(res => {
        // res.data.map(user => console.log(user));
        const otherUsers = res.data.filter(
          user => user._id !== decoded.id
        );

        this.setState({
          otherUsers,
          loading: false
        });

        // console.log("tyum", otherUsers)
      })
      .catch(res => {
        localStorage.clear();
        window.location.href = "/login";
      });
  }

  getTimeTillXmas() {
    const xmas = new Date("Dec 25, 2023 8:00:00").getTime();
    const now = new Date().getTime();

    const timeTill = xmas - now;

    const days = Math.floor(timeTill / (1000 * 60 * 60 * 24));

    this.setState({
      daysTillXmas: days
    });
  }

  easterEgg = () => {
    this.setState({
      showEgg: !this.state.showEgg
    });
  };

  render() {
    if (isEmpty(localStorage.getItem("jwtToken"))) {
      const jumboContent =
        this.state.showEgg === false ? (
          <div>
            <h1 className="display-4">
              <span
                aria-label="search"
                role="img"
                text-align="right"
                style={{ fontSize: "70px" }}
                className="ml-auto"
              >
                🎄
              </span>
              KANE FAM XMAS
            </h1>
            <button
              className="lead text-left p-0 m-0"
              onClick={this.easterEgg}
              style={{ border: "none", background: "none" }}
            >
              Login below to see your list and others'
            </button>
          </div>
        ) : (
          <div>
            <h1 className="display-4">
              A wild Easter egg appeared!
              <span
                aria-label="search"
                role="img"
                text-align="right"
                style={{ fontSize: "45px" }}
                className="ml-auto"
              >
                🥚
              </span>
            </h1>
            <button
              className="lead text-left p-0 m-0"
              onClick={this.easterEgg}
              style={{ border: "none", background: "none" }}
            >
              I already told you about this one, go find your own egg
            </button>
          </div>
        );

      return <Jumbotron>{jumboContent}</Jumbotron>;
    } else {
      if (this.state.loading === false) {
        // FIXME: Timeout is killing memory
        // this.getTimeTillXmas();

        const { name, id } = this.state.user;

        return (
          <div>
            <Row>
              <Col className="col-5 my-auto">
                <h3>Your List</h3>
              </Col>
              <Col className="col-7 text-right my-auto">
                {this.state.daysTillXmas > 0 ? (
                  <h6 className="mb-0">
                    <strong className="text-success">
                      {this.state.daysTillXmas}
                    </strong>{" "}
                    days until Christmas
                  </h6>
                ) : (
                  <h6 className="mb-0">
                    <strong className="text-success">Merry</strong>
                    <strong className="text-danger"> Christmas</strong>
                    <strong className="text-success">!</strong>
                    <strong className="text-danger">!</strong>
                  </h6>
                )}
              </Col>
            </Row>

            <ListItem name={name} id={id} color="warning" />
            <hr />

            <h3>Family Lists </h3>
            {this.state.otherUsers.map(user => {
              return (
                <ListItem
                  key={user._id}
                  name={user.name}
                  id={user._id}
                  count={user.count}
                  color="success"
                />
              );
            })}
          </div>
        );
      } else {
        return <p>Loading...</p>;
      }
    }
  }
}
export default Lists;
