import React, { Component } from "react";
import {
  Button,
  // Container,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import axios from "axios";
// import { Link } from "react-router-dom";

class RepeatModal extends Component {
  state = {
    modal: false,
    repeated: false
    // id: this.props.id,
    // item: this.props.item,
    // notes: this.props.notes,
    // user: this.props.user,
    // userGifted: this.props.userGifted,
    // submitted: false,
    // submitMessage: "",
    // color: "",
    // year: this.props.year
  };


  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      repeated: false
    });
  };

  refresh = () => {
    window.location.reload()
  }

//   handleChange = event => {
//     this.setState({ [event.target.name]: event.target.value });
//   };

repeatItem = e => {
    const {
        id,
        item,
        notes,
        link,
      //   userGifted,
      //   user,
      //   submitMessage,
      //   submitted,
        // year
      } = this.props;

    //   console.log("kena",id)
  
    const newPresent = {
      item,
      link,
      notes,
      sourcePresentID: id
    };

    axios
        .post("/api/presents/add", newPresent)
        .then(res => {
            // console.log("tome",res)
            this.setState({ repeated: true })
        })
  };

  render() {
    const { repeated } = this.state
    const {
      item,
      notes,
    //   userGifted,
    //   user,
    //   submitMessage,
    //   submitted,
      year
    } = this.props;

    return (
      <div>
         <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          onClick={this.toggle}
        >
           🔄
        </span>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Repeat on this year's list</ModalHeader>
          <ModalBody>
            <Card body outline className="mb-2 p-2">
              <CardTitle>{item}</CardTitle>
              <CardText>{notes}</CardText>
            </Card>
            <CardText>{`Originally on ${year} list`}</CardText>
            <hr />

        { repeated ? (
            <Row>
                <Col>
                    <Button color="danger" onClick={this.toggle} block>
                    Close
                    </Button>
                </Col>

                <Col>
                <Button color="dark" onClick={ this.refresh } block>
                    Go to 2023 List
                </Button>
                </Col>
          </Row>

        ) : (
            <Row>
              <Col>
                <Button color="danger" onClick={this.toggle} block>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button color="success" onClick={this.repeatItem} block>
                  Copy to 2023
                </Button>
              </Col>

              {/* <Col>{submitButton}</Col> */}
            </Row>

        )}

          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default RepeatModal;
