import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import classnames from "classnames";
import { Link, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import isEmpty from "../../validation/is-empty";

class Add extends Component {
  state = {
    item: "",
    link: null,
    notes: null,
    errors: "",
    addedPresent: ""
  };

  componentDidMount() {
    this.setCurrentUser();
  }

  setCurrentUser() {
    if (!isEmpty(localStorage.getItem("jwtToken"))) {
      const user = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(user);

      this.setState({
        user: decoded
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const newPresent = {
      item: this.state.item,
      link: this.state.link,
      notes: this.state.notes
    };

    axios
      .post("/api/presents/add", newPresent)
      .then(res => {
        const present = res.data.item;
        this.setState({
          item: "",
          link: "",
          notes: "",
          errors: "",
          addedPresent: present
        });

        // res.redirect(`list/${this.props.match.params.id}`);
      })
      .catch(err =>
        this.setState({
          errors: err.response.data
        })
      );
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { errors, addedPresent } = this.state;

    if (isEmpty(localStorage.getItem("jwtToken"))) {
      return (
        <div>
          <Redirect to="/login" />
        </div>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Add Item</h1>
              <p className="lead text-center">
                Make an addition to your Christmas list
              </p>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.item
                    })}
                    placeholder="Item (*required)"
                    name="item"
                    value={this.state.item}
                    onChange={this.onChange}
                  />
                  {errors.item && (
                    <div className="invalid-feedback">{errors.item}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Link (*should start with https)"
                    name="link"
                    value={this.state.link}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Notes"
                    name="notes"
                    value={this.state.notes}
                    onChange={this.onChange}
                  />
                </div>
                {addedPresent ? (
                  <div>
                    <p className="lead text-center">
                      <strong>{addedPresent}</strong> has been added to
                      <Link to={`/list/${this.state.user.id}`}> your list</Link>
                    </p>
                  </div>
                ) : (
                  <div />
                )}
                <input
                  type="submit"
                  className="btn btn-success btn-block mt-4"
                />{" "}
              </form>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default Add;
